<template>
  <div>
    <div class="question">
      <span class="question-title">* L'azienda\ente ha messo in atto prassi e/o procedure idonee a valutare se i/le lavoratori/trici hanno difficoltà a <span data-explained-by-term @click="showTerm('conciliazione-lavoro-famiglia')">conciliare il lavoro e la famiglia</span>?</span>
      <fieldset class="radio-set">
        <label>
          <input type="radio" name="reconciliation_is_reconciliation_assessed" :value="1" :checked="1 === surveyData.reconciliation_is_reconciliation_assessed" @change="setRadio('reconciliation_is_reconciliation_assessed', $event)">
          <span>Sì</span>
        </label>
        <label>
          <input type="radio" name="reconciliation_is_reconciliation_assessed" :value="2" :checked="2 === surveyData.reconciliation_is_reconciliation_assessed" @change="setRadio('reconciliation_is_reconciliation_assessed', $event)">
          <span>No, pur avendo messo in atto almeno una delle misure di conciliazione sotto elencate</span>
        </label>
        <label>
          <input type="radio" name="reconciliation_is_reconciliation_assessed" :value="3" :checked="3 === surveyData.reconciliation_is_reconciliation_assessed" @change="setRadio('reconciliation_is_reconciliation_assessed', $event)">
          <span>No</span>
        </label>
      </fieldset>

      <div class="indented" v-if="surveyData.reconciliation_is_reconciliation_assessed === 1">
        <span class="question-title">* A seguito delle informazioni reperite l'azienda\ente ha adottato misure atte a rispondere ai bisogni rilevati?</span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" name="reconciliation_is_reconciliation_assisted" :value="1" :checked="1 === surveyData.reconciliation_is_reconciliation_assisted" @change="setRadio('reconciliation_is_reconciliation_assisted', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is_reconciliation_assisted" :value="2" :checked="2 === surveyData.reconciliation_is_reconciliation_assisted" @change="setRadio('reconciliation_is_reconciliation_assisted', $event)">
            <span>No, perché non sono emerse esigenze di conciliazione</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is_reconciliation_assisted" :value="0" :checked="0 === surveyData.reconciliation_is_reconciliation_assisted" @change="setRadio('reconciliation_is_reconciliation_assisted', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>
    </div>

    <div :class="{ 'soft-hidden': !((surveyData.reconciliation_is_reconciliation_assessed === 1 && surveyData.reconciliation_is_reconciliation_assisted === 1) || surveyData.reconciliation_is_reconciliation_assessed === 2)}">
      <div class="question">
        <span class="question-title">* Nell'organizzazione dell'azienda\ente sono state attuate una o più misure di flessibilità (ad esempio flessibilità oraria, telelavoro, job-sharing)?</span>

        <fieldset class="radio-set">
          <label>
            <input type="radio" name="reconciliation_is_business_organisation_modified" :value="1" :checked="1 === surveyData.reconciliation_is_business_organisation_modified" @change="setRadio('reconciliation_is_business_organisation_modified', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is_business_organisation_modified" :value="2" :checked="2 === surveyData.reconciliation_is_business_organisation_modified" @change="setRadio('reconciliation_is_business_organisation_modified', $event)">
            <span>No, perché questa esigenza di conciliazione non è stata rilevata</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is_business_organisation_modified" :value="3" :checked="3 === surveyData.reconciliation_is_business_organisation_modified" @change="setRadio('reconciliation_is_business_organisation_modified', $event)">
            <span>No, perché non attuabili per la specifica attività lavorativa</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is_business_organisation_modified" :value="0" :checked="0 === surveyData.reconciliation_is_business_organisation_modified" @change="setRadio('reconciliation_is_business_organisation_modified', $event)">
            <span>No</span>
          </label>
        </fieldset>

        <div class="indented" v-if="surveyData.reconciliation_is_business_organisation_modified === 1">
          <span class="question-title">* Specificare gli strumenti utilizzati</span>
          <fieldset class="checkbox-set">
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_business_organisation_modified_1" @change="setCheckbox('reconciliation_business_organisation_modified_1', $event)">
              <span data-explained-by-term @click="showTerm('flessibilita-orario-di-lavoro')">Flessibilità oraria (in entrata e in uscita)</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_business_organisation_modified_2" @change="setCheckbox('reconciliation_business_organisation_modified_2', $event)">
              <span data-explained-by-term @click="showTerm('telelavoro')">Telelavoro</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_business_organisation_modified_3" @change="setCheckbox('reconciliation_business_organisation_modified_3', $event)">
              <span data-explained-by-term @click="showTerm('job-sharing')">Job-sharing</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_business_organisation_modified_4" @change="setCheckbox('reconciliation_business_organisation_modified_4', $event)">
              <span>Promozione della <span data-explained-by-term @click="showTerm('banca-delle-ore')">Banca delle ore</span> tra i dipendenti (<span data-referring-to-law @click="showLaw('l-53-2000')">L. 53/2000</span>)</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_business_organisation_modified_other" @change="setCheckbox('reconciliation_business_organisation_modified_other', $event)">
              <span>Altro</span>
            </label>
            <div class="extra" v-if="surveyData.reconciliation_business_organisation_modified_other === 1">
              <label>Specificare: <input type="text" :value="surveyData.reconciliation_business_organisation_modified_other_what" @input="setText('reconciliation_business_organisation_modified_other_what', $event)"></label>
            </div>
          </fieldset>
        </div>
      </div>

      <div class="question">
        <span class="question-title">* Nell'assegnazione dei turni si è venuti incontro ad <span data-explained-by-term @click="showTerm('conciliazione-lavoro-famiglia')">esigenze di conciliazione</span>?</span>

        <fieldset class="radio-set">
          <label>
            <input type="radio" name="reconciliation_is_work_shift_planning_reconciliation_weighted" :value="1" :checked="1 === surveyData.reconciliation_is_work_shift_planning_reconciliation_weighted" @change="setRadio('reconciliation_is_work_shift_planning_reconciliation_weighted', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is_work_shift_planning_reconciliation_weighted" :value="2" :checked="2 === surveyData.reconciliation_is_work_shift_planning_reconciliation_weighted" @change="setRadio('reconciliation_is_work_shift_planning_reconciliation_weighted', $event)">
            <span>No, perché questa esigenza di conciliazione non è stata rilevata</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is_work_shift_planning_reconciliation_weighted" :value="3" :checked="3 === surveyData.reconciliation_is_work_shift_planning_reconciliation_weighted" @change="setRadio('reconciliation_is_work_shift_planning_reconciliation_weighted', $event)">
            <span>No, perché non attuabili per la specifica attività lavorativa</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is_work_shift_planning_reconciliation_weighted" :value="0" :checked="0 === surveyData.reconciliation_is_work_shift_planning_reconciliation_weighted" @change="setRadio('reconciliation_is_work_shift_planning_reconciliation_weighted', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>

      <div class="question">
        <span class="question-title">* Nell'assegnazione del lavoro notturno si è venuti incontro ad <span data-explained-by-term @click="showTerm('conciliazione-lavoro-famiglia')">esigenze di conciliazione</span>?</span>

        <fieldset class="radio-set">
          <label>
            <input type="radio" name="reconciliation_is-night-work-reconciliation-weighted" :value="1" :checked="1 === surveyData.reconciliation_is_night_work_reconciliation_weighted" @change="setRadio('reconciliation_is_night_work_reconciliation_weighted', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is-night-work-reconciliation-weighted" :value="2" :checked="2 === surveyData.reconciliation_is_night_work_reconciliation_weighted" @change="setRadio('reconciliation_is_night_work_reconciliation_weighted', $event)">
            <span>No, perché questa esigenza di conciliazione non è stata rilevata</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is-night-work-reconciliation-weighted" :value="3" :checked="3 === surveyData.reconciliation_is_night_work_reconciliation_weighted" @change="setRadio('reconciliation_is_night_work_reconciliation_weighted', $event)">
            <span>No, perché non attuabili per la specifica attività lavorativa</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is-night-work-reconciliation-weighted" :value="0" :checked="0 === surveyData.reconciliation_is_night_work_reconciliation_weighted" @change="setRadio('reconciliation_is_night_work_reconciliation_weighted', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>

      <div class="question">
        <span class="question-title">* L'azienda\ente ha messo a disposizione dei/lle lavoratori/trici uno o più servizi/misure in risposta alle <span data-explained-by-term @click="showTerm('conciliazione-lavoro-famiglia')">esigenze di conciliazione</span>?</span>

        <fieldset class="radio-set">
          <label>
            <input type="radio" name="reconciliation_is-reconciliation-facilitated" :value="1" :checked="1 === surveyData.reconciliation_is_reconciliation_facilitated" @change="setRadio('reconciliation_is_reconciliation_facilitated', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is-reconciliation-facilitated" :value="2" :checked="2 === surveyData.reconciliation_is_reconciliation_facilitated" @change="setRadio('reconciliation_is_reconciliation_facilitated', $event)">
            <span>No, perché questa esigenza di conciliazione non è stata rilevata</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is-reconciliation-facilitated" :value="3" :checked="3 === surveyData.reconciliation_is_reconciliation_facilitated" @change="setRadio('reconciliation_is_reconciliation_facilitated', $event)">
            <span>No, perché non attuabili</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_is-reconciliation-facilitated" :value="0" :checked="0 === surveyData.reconciliation_is_reconciliation_facilitated" @change="setRadio('reconciliation_is_reconciliation_facilitated', $event)">
            <span>No</span>
          </label>
        </fieldset>

        <div v-if="surveyData.reconciliation_is_reconciliation_facilitated === 1">
          <span class="question-title">* Specificare gli strumenti utilizzati</span>

          <fieldset class="checkbox-set">
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_work_family_facility_1" @change="setCheckbox('reconciliation_work_family_facility_1', $event)">
              <span>Nido aziendale</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_work_family_facility_2" @change="setCheckbox('reconciliation_work_family_facility_2', $event)">
              <span>Nido convenzionato</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_work_family_facility_3" @change="setCheckbox('reconciliation_work_family_facility_3', $event)">
              <span>Sostegni all'attività di cura (portatori di handicap, anziani autosufficienti) centri e campi estivi per bambini e ragazzi</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_work_family_facility_4" @change="setCheckbox('reconciliation_work_family_facility_4', $event)">
              <span><span data-explained-by-term @click="showTerm('counselling')">Counselling</span>/<span data-explained-by-term @click="showTerm('mentoring')">mentoring</span></span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_work_family_facility_5" @change="setCheckbox('reconciliation_work_family_facility_5', $event)">
              <span>Babysitter a domicilio</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_work_family_facility_6" @change="setCheckbox('reconciliation_work_family_facility_6', $event)">
              <span>Sostegni economici finalizzati (voucher)</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_work_family_facility_7" @change="setCheckbox('reconciliation_work_family_facility_7', $event)">
              <span>Supporto di sollievo domestico (es. cena da asporto per i dipendenti e la famiglia in caso di servizio mensa)</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_work_family_facility_other" @change="setCheckbox('reconciliation_work_family_facility_other', $event)">
              <span>Altro</span>
            </label>
            <div class="extra" v-if="surveyData.reconciliation_work_family_facility_other === 1">
              <label>Specificare: <input type="text" :value="surveyData.reconciliation_work_family_facility_other_what" @input="setText('reconciliation_work_family_facility_other_what', $event)"></label>
            </div>
          </fieldset>
        </div>
      </div>

      <div class="question">
        <span class="question-title">* L'azienda\ente ha mai usufruito dei finanziamenti erogati sulla base dell'art.9 della L.53/2000 per la riorganizzazione del lavoro in modo da favorire la <span data-explained-by-term @click="showTerm('conciliazione-lavoro-famiglia')">conciliazione lavoro-famiglia</span>?</span>

        <fieldset class="radio-set">
          <label>
            <input type="radio" name="reconciliation-has-company-requested-art9-founds" :value="1" :checked="1 === surveyData.reconciliation_has_company_requested_art9_founds" @change="setRadio('reconciliation_has_company_requested_art9_founds', $event)">
            <span>Sì</span>
          </label>
          <label>
            <input type="radio" name="reconciliation-has-company-requested-art9-founds" :value="3" :checked="3 === surveyData.reconciliation_has_company_requested_art9_founds" @change="setRadio('reconciliation_has_company_requested_art9_founds', $event)">
            <span>No, l'azienda\ente ha deciso di riorganizzare il lavoro in modo da favorire la conciliazione lavoro-famiglia con fondi propri</span>
          </label>
          <label>
            <input type="radio" name="reconciliation-has-company-requested-art9-founds" :value="4" :checked="4 === surveyData.reconciliation_has_company_requested_art9_founds" @change="setRadio('reconciliation_has_company_requested_art9_founds', $event)">
            <span>No, perché il DL non era a conoscenza della legge</span>
          </label>
          <label>
            <input type="radio" name="reconciliation-has-company-requested-art9-founds" :value="5" :checked="5 === surveyData.reconciliation_has_company_requested_art9_founds" @change="setRadio('reconciliation_has_company_requested_art9_founds', $event)">
            <span>No, perché non è stato più disponibile il bando di legge</span>
          </label>
          <label>
            <input type="radio" name="reconciliation-has-company-requested-art9-founds" :value="2" :checked="2 === surveyData.reconciliation_has_company_requested_art9_founds" @change="setRadio('reconciliation_has_company_requested_art9_founds', $event)">
            <span>No, l'azienda\ente ha fatto richiesta ma non ha ottenuto i finanziamenti</span>
          </label>
          <label>
            <input type="radio" name="reconciliation-has-company-requested-art9-founds" :value="0" :checked="0 === surveyData.reconciliation_has_company_requested_art9_founds" @change="setRadio('reconciliation_has_company_requested_art9_founds', $event)">
            <span>No</span>
          </label>
        </fieldset>
      </div>

      <div class="question">
        <span class="question-title">* L'azienda\ente prevede, come modalità operativa, l'adozione di misure rivolte a facilitare il rientro in servizio dopo un periodo di assenza per <span data-explained-by-term @click="showTerm('congedo-di-maternita')">congedo di maternità</span> o <span data-explained-by-term @click="showTerm('congedo-parentale')">parentale</span> prolungato (ad esempio almeno 2 mesi)?</span>
        <fieldset class="radio-set">
          <label>
            <input type="radio" name="reconciliation_coming-back-to-work-is-facilitated" :value="2" :checked="2 === surveyData.reconciliation_coming_back_to_work_is_facilitated" @change="setRadio('reconciliation_coming_back_to_work_is_facilitated', $event)">
            <span>Sì, in ogni caso</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_coming-back-to-work-is-facilitated" :value="1" :checked="1 === surveyData.reconciliation_coming_back_to_work_is_facilitated" @change="setRadio('reconciliation_coming_back_to_work_is_facilitated', $event)">
            <span>Sì, se nel frattempo ci sono state variazioni organizzative o dei metodi di lavoro</span>
          </label>
          <label>
            <input type="radio" name="reconciliation_coming-back-to-work-is-facilitated" :value="0" :checked="0 === surveyData.reconciliation_coming_back_to_work_is_facilitated" @change="setRadio('reconciliation_coming_back_to_work_is_facilitated', $event)">
            <span>No</span>
          </label>
        </fieldset>

        <div v-if="surveyData.reconciliation_coming_back_to_work_is_facilitated === 1 || surveyData.reconciliation_coming_back_to_work_is_facilitated === 2">
          <span class="question-title">* Specificare gli strumenti utilizzati</span>
          <fieldset class="checkbox-set">
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_coming_back_facility_1" @change="setCheckbox('reconciliation_coming_back_facility_1', $event)">
              <span><span data-explained-by-term @click="showTerm('counselling')">Counselling</span> al rientro in servizio</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_coming_back_facility_2" @change="setCheckbox('reconciliation_coming_back_facility_2', $event)">
              <span>Attività di aggiornamento al rientro in servizio</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_coming_back_facility_3" @change="setCheckbox('reconciliation_coming_back_facility_3', $event)">
              <span>Assegnazione di compiti graduali al rientro in servizio</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_coming_back_facility_4" @change="setCheckbox('reconciliation_coming_back_facility_4', $event)">
              <span>Attività di aggiornamento, durante il periodo di congedo, previa disponibilità del/lla lavoratore/lavoratrice</span>
            </label>
            <label>
              <input type="checkbox" :value="1" :checked="1 === surveyData.reconciliation_coming_back_facility_other" @change="setCheckbox('reconciliation_coming_back_facility_other', $event)">
              <span>Altro</span>
            </label>
            <div class="extra" v-if="surveyData.reconciliation_coming_back_facility_other === 1">
              <label>Specificare: <input type="text" :value="surveyData.reconciliation_coming_back_facility_other_what" @input="setText('reconciliation_coming_back_facility_other_what', $event)"></label>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <InfoModal v-if="$modals.terms" modal-class="term-modal" :title="term" @close="closeTerm">
      <div v-html="termText"></div>
    </InfoModal>
    <InfoModal v-if="$modals.laws" modal-class="law-modal" :title="law" @close="closeLaw">
      <h2>{{ lawTitle }}</h2>
      <div v-html="lawText"></div>
    </InfoModal>
  </div>
</template>

<script>

import surveyMixin from '@/mixins/surveyMixin.js';
import stepTitleFilter from '@/filters/stepTitleFilter.js';
import InfoModal from '@/libs/Modals/components/InfoModal.vue';

export default {
  mixins: [surveyMixin],
  filters: {
    stepTitle: stepTitleFilter,
  },
  components: {
    InfoModal,
  },
  props: {
    edit: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {};
  },
};

</script>
